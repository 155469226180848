body.migrations {
    div.card-head {
        a.btn-card-head {
            position: absolute;
            right: 15px;
            top: 18px;
        }
    }

    tr.clickable-row {
        cursor: pointer;
    }

    div.table-responsive {
        padding: 15px;

        div.progress {
            margin-top: 9px;
        }
    }

    span.ax_status {
        font-size: 18px;
    }

    div.ax_logs {
        background: #fafafa;
        border: 1px solid #dedede;
        font-family: Consolas;
        font-size: 14px;
        height: 150px;
        line-height: 1.8;
        overflow-y: scroll;
        padding: 10px;
        width: 100%;
    }

    div.migration_sidebar {
        div.wrap {
            position: relative;
            border-right: 1px solid #dbe2ea;
        }

        div.migration_list {
            overflow-y: scroll;
            height: 65vh;

            ul {
                margin: 0;
                padding-bottom: 60px;

                li {
                    a.migration {
                        border-bottom: 1px solid #DAE1E9;
                        display: block;
                        padding: 20px;
                        position: relative;

                        div.status_wrap {
                            color: #99abb4;
                            font-size: 14px;
                        }

                        div.status {
                            display: inline-block;

                            i {
                                color: #59c375;
                            }

                            &.complete {
                                svg {
                                    fill: #9aacb5;
                                    height: 24px;
                                    width: 24px;
                                }
                            }

                            &.processing {
                                background: #f1a92b;
                                border-radius: 100%;
                                height: 25px;
                                line-height: 33px;
                                text-align: center;
                                width: 25px;

                                svg {
                                    height: 18px;
                                    width: 18px;
                                    fill: #fff;
                                }
                            }
                        }
                    }

                    &:last-of-type {
                        a.migration {
                            border-bottom: 0;
                        }
                    }

                    &.active {
                        a {
                            background: #f9fbfc;
                        }
                    }
                }
            }
        }

        div.new_migration {
            background: #ffff;
            border-top: 1px solid #dbe2ea;
            bottom: 0;
            cursor: pointer;
            display: inline-block;
            padding: 20px;
            position: absolute;
            text-align: center;
            width: 100%;

            a {
                color: #9BA6B2;
            }

            &:hover {
                background: #f9fbfc;
            }
        }
    }
}

div.migration_content {
    h3 {
        i {
            font-size: 18px;
        }
    }

    i {
        color: $sidebar-icons;
        font-size: 24px;
    }
}
