$themecolor: #0667D0;
$themecolor-dark: #028ee1;
$topbar: #0667D0;
$topbar-alt: #ffffff;
$themecolor-alt: #26c6da;

.topbar {
    background: $topbar;

    .navbar-header {
        background: $themecolor;
    }

    .top-navbar .navbar-header .navbar-brand .dark-logo {
        display: none;
    }

    .top-navbar .navbar-header .navbar-brand .light-logo {
        display: inline-block;
        color: rgba(255, 255, 255, 0.8);
    }

    .navbar-light .navbar-nav .nav-item > {
        a.nav-link {
            color: $white!important;

            &:focus,
            &:hover {
                color: rgba(255, 255, 255, 0.8)!important;
            }
        }
    }
}
/*******************
/*General Elements
*******************/
a.link {
    &:focus,
    &:hover {
        color: $themecolor!important;
    }
}

.bg-theme {
    background-color: $themecolor !important;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
    background-color: $themecolor;
    border-color: $themecolor;
}

.right-sidebar {
    .rpanel-title {
        background: $themecolor;
    }
}

.stylish-table {
    tbody tr {
        &.active,
        &:hover {
            border-left: 4px solid $themecolor;
        }
    }
}

.text-themecolor {
    color: $themecolor!important;
}

.customtab,
.profile-tab {
    li {
        a.nav-link {
            &.active {
                border-bottom: 2px solid $themecolor;
                color: $themecolor;
            }

            &:hover {
                color: $themecolor;
            }
        }
    }
}
/*******************
/*Buttons
*******************/
.btn-themecolor,
.btn-themecolor.disabled {
    background: $themecolor;
    color: $white;
    border: 1px solid $themecolor;

    &:hover {
        background: $themecolor;
        opacity: 0.7;
        border: 1px solid $themecolor;
    }

    &.active,
    &:focus {
        background: $themecolor-dark;
    }
}
/*******************
/*sidebar navigation
*******************/
.label-themecolor {
    background: $themecolor;
}

.sidebar-nav > ul > li.active > a {
    color: $themecolor;
    border-color: $themecolor;

    i {
        color: $themecolor;
    }
}

.sidebar-nav ul li a {
    &.active,
    &:hover {
        color: $themecolor;

        i {
            color: $themecolor;
        }
    }
}
