$mdi-font-path: "../../fonts/backend";
$flag-icon-css-path: '../../images/backend/flags';
$fa-font-path:        "../../fonts/backend";

@font-face {
    font-family: 'Avenir Next';
    src: url('../../fonts/backend/AvenirNextLTPro-Regular.eot');
    src: url('../../fonts/backend/AvenirNextLTPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/backend/AvenirNextLTPro-Regular.woff') format('woff'),
        url('../../fonts/backend/AvenirNextLTPro-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
//
// @font-face {
//     font-family: 'Avenir Next';
//     src: url('../../fonts/backend/AvenirNextLTPro-Medium.eot');
//     src: url('../../fonts/backend/AvenirNextLTPro-Medium.eot?#iefix') format('embedded-opentype'),
//         url('../../fonts/backend/AvenirNextLTPro-Medium.woff') format('woff'),
//         url('../../fonts/backend/AvenirNextLTPro-Medium.ttf') format('truetype');
//     font-weight: 500;
//     font-style: normal;
// }
//
// @font-face {
//     font-family: 'Avenir Next';
//     src: url('../../fonts/backend/AvenirNextLTPro-Demi.eot');
//     src: url('../../fonts/backend/AvenirNextLTPro-Demi.eot?#iefix') format('embedded-opentype'),
//         url('../../fonts/backend/AvenirNextLTPro-Demi.woff') format('woff'),
//         url('../../fonts/backend/AvenirNextLTPro-Demi.ttf') format('truetype');
//     font-weight: 700;
//     font-style: normal;
// }


// Body
$body-bg: #f5f8fa;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: #3097D1;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger: #bf5329;

// Typography
$font-family-sans-serif: "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
$bodyfont: "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
$headingfont: "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

$font-size-base: 16px;
$line-height-base: 1.6;
$text-color: #636b6f;

// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;

/*Theme Colors*/
$topbar: #0667D0;
$sidebar: #fff;
$sidebar-white: #e5edef;
$sidebar-alt: #f2f6f8;
$bodycolor: #f4f7fa9e;
$headingtext: #455a64;
$bodytext: #67757c;
$sidebar-text: #607d8b;
$sidebar-icons: #99abb4;
$light-text: #a6b7bf;
$themecolor: #0667D0;
$themecolor-alt: #26c6da;
$themecolor-dark: #028ee1;

/*bootstrap Color*/
$danger: #ef5350;
$success: #58c575;
$warning: #ffb22b;
$primary: #58c575;
$info: #0667D0;
$inverse: #2f3d4a;
$muted: #99abb4;
$dark: #263238;
$light: #f2f4f842;
$extra-light: #ebf3f5;
$bglight: rgba(0, 0, 0, 0.02);

/*Light colors*/
$light-danger: #f9e7eb;
$light-success: #e8fdeb;
$light-warning: #fff8ec;
$light-primary: #5ed67e;
$light-info: #cfecfe;
$light-inverse: #f6f6f6;
$light-megna: #e0f2f4;
$danger-dark: #e6294b;
$success-dark: #1eacbe;
$warning-dark: #e9ab2e;
$primary-dark: #52b36c;
$info-dark: #028ee1;
$red-dark: #d61f1f;
$inverse-dark: #232a37;
$dark-transparent: rgba(0, 0, 0, 0.05);
$info-shadow: 0 2px 2px 0 rgba(66,165,245,.14), 0 3px 1px -2px rgba(66,165,245,.2), 0 1px 5px 0 rgba(66,165,245,.12);
$info-shadow-hover: 0 14px 26px -12px rgba(23,105,255,.42), 0 4px 23px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(23,105,255,.2);
$warning-shadow: 0 2px 2px 0 rgba(248,194,0,.14), 0 3px 1px -2px rgba(248,194,0,.2), 0 1px 5px 0 rgba(248,194,0,.12);
$warning-shadow-hover: 0 14px 26px -12px rgba(248,194,0,.42), 0 4px 23px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(248,194,0,.2);
$danger-shadow: 0 2px 2px 0 rgba(239,83,80,.14), 0 3px 1px -2px rgba(239,83,80,.2), 0 1px 5px 0 rgba(239,83,80,.12);
$danger-shadow-hover: 0 14px 26px -12px rgba(239,83,80,.42), 0 4px 23px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(239,83,80,.2);
$success-shadow: 0 2px 2px 0 rgba(40,190,189,.14), 0 3px 1px -2px rgba(40,190,189,.2), 0 1px 5px 0 rgba(40,190,189,.12);
$success-shadow-hover: 0 14px 26px -12px rgba(40,190,189,.42), 0 4px 23px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(40,190,189,.2);
$primary-shadow: 0 2px 2px 0 rgba(116,96,238,.14), 0 3px 1px -2px rgba(116,96,238,.2), 0 1px 5px 0 rgba(116,96,238,.12);
$primary-shadow-hover: 0 14px 26px -12px rgba(116,96,238,.42), 0 4px 23px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(116,96,238,.2);
$default-shadow: 0 2px 2px 0 rgba(169,169,169,.14), 0 3px 1px -2px rgba(169,169,169,.2), 0 1px 5px 0 rgba(169,169,169,.12);
$default-shadow-hover: 0 14px 26px -12px rgba(169,169,169,.42), 0 4px 23px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(169,169,169,.2);

/*Normal Color*/
$white: #ffffff;
$red: #fb3a3a;
$yellow: #a0aec4;
$purple: #7460ee;
$blue: #02bec9;
$megna: #00897b;
$rgt: right;
$lft: left;
$border: #DAE1E9;
$table-border: #f3f1f1;
$card-brd: #d7dfe3;
$dark-text: #848a96;
$radius: 4px;
$form-brd: #b1b8bb;

.preloader {
    width: 100%;
    height: 100%;
    top: 0;
    position: fixed;
    z-index: 99999;
    background: #fff;

    .cssload-speeding-wheel {
        position: absolute;
        top: calc(50% - 3.5px);
        left: calc(50% - 3.5px);
    }
}
