.left-sidebar {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 20;
    padding: 70px 15px 0;
    background: $sidebar;
    border-bottom: 1px solid $border;
}

.fix-sidebar .left-sidebar {
    position: fixed;
}

.scroll-sidebar {
    margin: 0 auto;
    max-width: 1250px;
    padding: 0 15px;
}

.collapse.in {
    display: block;
}

.sidebar-nav {
    background: $sidebar;
    padding: 0;

    ul {
        margin: 0;
        padding: 0;

        li {
            list-style: none;

            a {
                border-bottom: 1px solid transparent;
                color: $sidebar-text;
                display: block;
                font-size: 16px;
                margin: 0 30px;
                padding: 14px 0;
                white-space: nowrap;
                max-height: 53px;

                &:hover {
                    border-bottom: 1px solid;
                    color: $themecolor;

                    i {
                        color: $themecolor;
                    }
                }

                &.active {
                    font-weight: 500;
                    color: $dark;
                }
            }

            ul {
                padding: 15px;
                top: 53px;

                li a {
                    padding: 7px 10px;
                }

                ul {
                    padding-left: 15px;
                    padding-top: 0;
                }
            }

            &.nav-devider,
            &.nav-small-cap {
                display: none;
            }
        }

        &:first-of-type {
            a {
                margin-left: 0;
            }
        }
    }

    > ul > li > a {
        i {
            width: 27px;
            font-size: 17px;
            display: inline-block;
            vertical-align: middle;
            color: $sidebar-icons;
        }

        .label {
            float: right;
            margin-top: 6px;
        }

        &.active {
            font-weight: 400;
            //background:#242933;
            color: $themecolor-alt;
        }
    }

    > ul > li {
        &.active > a {
            color: $themecolor;
            font-weight: 500;

            i {
                color: $themecolor;
            }
        }
    }
}

.sidebar-nav .has-arrow {
    position: relative;

    &::after {
        position: absolute;
        content: '';
        width: 7px;
        height: 7px;
        border-width: 1px 0 0 1px;
        border-style: solid;
        border-color: $sidebar-text;
        right: 1em;
        -webkit-transform: rotate(135deg) translate(0, -50%);
        -ms-transform: rotate(135deg) translate(0, -50%);
        -o-transform: rotate(135deg) translate(0, -50%);
        transform: rotate(135deg) translate(0, -50%);
        -webkit-transform-origin: top;
        -ms-transform-origin: top;
        -o-transform-origin: top;
        transform-origin: top;
        top: 47%;
        -webkit-transition: all 0.3s ease-out;
        -o-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
    }
}

.sidebar-nav .active > .has-arrow::after,
.sidebar-nav .has-arrow[aria-expanded="true"]::after,
.sidebar-nav li > .has-arrow.active::after {
    -webkit-transform: rotate(-135deg) translate(0, -50%);
    -ms-transform: rotate(-135deg) translate(0, -50%);
    -o-transform: rotate(-135deg) translate(0, -50%);
    top: 45%;
    width: 7px;
    transform: rotate(-135deg) translate(0, -50%);
}

.sidebar-nav #sidebarnav > li > a.has-arrow:after {
    display: none;
}
@media(max-width:1024px) and (min-width:700px) {
    .sidebar-nav ul li a i {
        display: none;
    }
}
/****************
When click on sidebar toggler and also for tablet
*****************/
@media(min-width:768px) {
    .sidebar-nav > ul > li {
        float: left;

        &:hover > a,
        &:hover > a i {
            color: $themecolor;
        }
    }

    .sidebar-nav > ul > li:last-child ul {
        right: 0;
    }

    .sidebar-nav #sidebarnav li.two-column > ul {
        width: 400px;

        li {
            width: 49%;
            vertical-align: top;
            display: inline-block;
        }
    }

    .sidebar-nav #sidebarnav li.three-column > ul {
        width: 550px;

        li {
            width: 32.5%;
            vertical-align: top;
            display: inline-block;
        }
    }

    .sidebar-nav #sidebarnav > li > a.has-arrow:after {
        display: none;
    }

    .sidebar-nav #sidebarnav li {
        position: relative;
    }

    .sidebar-nav #sidebarnav > li > ul {
        position: absolute;
        z-index: 1001;
        width: 240px;
        background: $white;
        display: none;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.09);
    }

    .sidebar-nav #sidebarnav > li:hover > ul {
        height: auto !important;
        overflow: auto;
    }

    .sidebar-nav #sidebarnav > li:hover > ul,
    .sidebar-nav #sidebarnav > li:hover > ul.collapse {
        display: block;
        max-height: 440px;
    }
}
@media(min-width:768px) and (max-width:768px) {
    .sidebar-nav > ul > li > ul {
        top: 48px;
    }
}
@media(max-width:767px) {
    .mini-sidebar {
        .left-sidebar {
            position: fixed;
            display: none;
            height: 100%;
            width: 260px;
            padding: 60px 0 0;
            overflow: auto;
        }

        &.show-sidebar {
            .left-sidebar,
            .sidebar-footer {
                display: block;
            }
        }

        .sidebar-nav #sidebarnav > li > ul {
            padding: 0 10px;

            li a {
                padding: 10px 15px 10px 35px;
            }
        }
    }

    .sidebar-nav #sidebarnav > li > a.has-arrow:after {
        display: block;
    }
}
